/* eslint-disable @typescript-eslint/no-unused-vars */
import { type GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-pro';
import { cloneDeep } from 'lodash';
import React, { ReactNode, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  alarmCardMapper,
  CustomDialog,
  CustomStatus,
  CustomTooltipWithLabel,
  downloadFile,
  FilterLayout,
  FilterProp,
  LinkButton,
  renderHeaderActionButtons,
  SidePopover,
  TableDashboard,
  useAppDispatch,
  useAppSelector,
} from '../../globalUtils/globalExports';
import {
  deleteCharger,
  exportToCsvForChargerList,
  setChargerListTableProps,
  setRedirectedStateForChargerFilters,
} from '../../rmsReduxStore/chargersRedux/chargerCreators';
import {
  chargerTypes,
  fetchChargersData,
  getChargerReducer,
  getCurrentFiltersValues,
  getCurrentScreenAppliedFiltersCount,
  getFilterReducer,
  getFilterSelectedValue,
  getGlobalReducer,
  getReferenceDataReducer,
  setFilterData,
  setTableColumnPrefrences
} from '../../rmsReduxStore/reduxExports';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import './chargers.css';
import { Id, toast } from 'react-toastify';
import { ActionIcons, IconWithTooltip } from '../../globalUtils/globalIcons';
import { NoDataComponentChargers } from '../../globalUtils/TableDashboard/TableComponents';
import { createBreadCrumbProps, formatRelativeTimeForTable } from '../../globalUtils/globalHooks';
import access from '../../auth/service/AccessControl';
import { AddBulkCharger } from '../componentExports';

export interface MapTooltipData {
  id: string,
  headerText: string;
  headerOnClick: () => void
  data: {
    icon: string;
    text: string;
  }[];
}

export interface IMapFocusedData {
  latitude: number;
  longitude: number;
  id: string
}

const ManageChargersDashboard = memo(function ManageChargersDashboard() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const enableVisibilityEdit = access?.hasChargerVisibilityEditAccess()
  const isOrgTypeCustomer = access?.isOrgTypeCustomer();
  const layouts = ['list', 'map'];
  const { chargers, chargerCount, chargerTableLoader, chargerListTableProps, chargerFiltersAppliedFromRedirection } = useAppSelector(getChargerReducer);
  const {
    chargerStatusReferenceData,
    chargerLocationReferenceData,
    softwareVersionReferenceData,
    alarmSeverityReferenceData,
    chargerTypeReferenceData,
    chargerVisibilityReferenceData,
    chargingStationReferenceData,
    chargerProductTypeReferenceData,
    chargerActiveCertificateReferenceData,
  } = useAppSelector(getReferenceDataReducer);
  const { screenFilters } = useAppSelector(getFilterReducer)
  const { screenTableColumnPrefrences } = useAppSelector(getGlobalReducer)
  const [layoutView, setLayoutView] = useState<string>(location?.state?.layoutView === 'map' ? 'map' : 'list');
  const [individualChargerMapView, setIndividualChargerMapView] = useState(location?.state?.individualChargerMapView || false);
  const [mapDataPoints, setMapDataPoints] = useState<{
    latitude: number;
    longitude: number;
    status: string,
    id: string,
    tooltipData: MapTooltipData
  }[]>();
  const [mapSelectedChargerDataPoint, setMapSelectedChargerDataPoint] = useState<IMapFocusedData>({ latitude: 0, longitude: 0, id: '' })
  // const [showChargerFilterScreen, setShowChargerFilterScreen] = useState(false);
  const [defaultMapCenter, setDefaultMapCenter] = useState(true);
  const [exportAnchorEl, setExportAnchorEl] = useState(null);
  const [addChargerOption, setAddChargerOption] = useState(null);
  const [isSidePopOverOpen, setIsSidePopOverOpen] = useState(false);
  const [exportingFile, setExportingFile] = useState(false);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const chargerListTablePropsRef = useRef(chargerListTableProps);
  const chargersRef = useRef(chargers);
  const [headerIndividualChargerMapView, setHeaderIndividualChargerMapView] = useState('Charger')
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isNoProductTypeDialogOpen, setIsNoProductTypeDialogOpen] = useState(false);
  const [selectedChargerId, setSelectedChargerId] = useState<string | null>(null);
  const [deleteLoader,setDeleteLoader] = useState<boolean>(false);



  // ********************************************
  // TODO: Callback wasn't taking the updated value
  useEffect(() => {
    chargerListTablePropsRef.current = chargerListTableProps;
  }, [chargerListTableProps]);

  useEffect(() => {
    chargersRef.current = chargers;
    if (layoutView === 'map') {
      refreshMapDataPoints(chargers)
    }
  }, [chargers]);

  // TODO: Handles global search box
  // In config - pass id, classname and onChange
  // In onChange - just add a callback rest is handled in search box component
  const onChangeOfSearchText = useCallback(async (val) => {
    if (chargerListTablePropsRef?.current) {
      await layoutCallback(1, chargerListTablePropsRef?.current?.pageSize, chargerListTablePropsRef?.current?.view, chargerListTablePropsRef?.current?.sortBy, chargerListTablePropsRef?.current?.order, null, val)
    }
    if (layoutView === 'map') {
      await refreshMapDataPoints(chargersRef?.current)
    }
  }, [layoutView, chargerListTablePropsRef?.current, chargersRef?.current]);

  const searchBoxConfiguration = useMemo(() => {
    return {
      searchFieldId: 'manage-chargers-search-box',
      searchFieldName: 'manage-chargers-search-box',
      handleSearch: onChangeOfSearchText,
    };
  }, [onChangeOfSearchText, layoutView, chargerListTablePropsRef?.current]);

  // TODO: Adds action btns in header and their callbacks
  const headerActionBtns = renderHeaderActionButtons({
    actionBtns:
      [
        {
          id: 'add_new_btn',
          isAccessible: access?.chargers?.add(),
          headerActionBtnClick: (event): void => {

            setAddChargerOption(event.currentTarget);
          }
        },
        {
          id: 'add_filter_btn',
          isAccessible: access?.chargers?.view(),
          headerActionBtnClick: (): void => {
            setFiltersVisible(true);
          },
          badgeCount: getCurrentScreenAppliedFiltersCount('chargers')
        },
        {

          id: 'export_csv_btn',
          isAccessible: access?.chargers?.export(),
          headerActionBtnClick: (event): void => {
            setExportAnchorEl(event.currentTarget);

          }
        },
      ]
  })

  // TODO: Map Layout callbacks
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onMapIconClick = useCallback(async (event, data) => {
    const charger: ICharger = data;
    const mapData: IMapFocusedData = {
      latitude: Number(charger?.latitude) || 0,
      longitude: Number(charger?.longitude) || 0,
      id: charger?.id
    };
    setMapSelectedChargerDataPoint(mapData)
    await refreshMapDataPoints([data])
    setHeaderIndividualChargerMapView(charger?.customerName + ', ' + charger?.city);
    setLayoutView('map');
    setIndividualChargerMapView(true);
    setDefaultMapCenter(false)
    history.pushState(null, '', '/manage-chargers');
    const tableProps: IChargerListTableProps = cloneDeep(chargerListTablePropsRef.current)
    tableProps.searchTerm = ''
    dispatch(setChargerListTableProps(tableProps))
  }, [mapSelectedChargerDataPoint, defaultMapCenter, headerIndividualChargerMapView, chargerListTablePropsRef?.current]);

  const refreshMapDataPoints = useCallback(async (chargerValue) => {
    try {
      const mapDataPoint = chargerValue !== null && chargerValue?.length > 0 && chargerValue?.map((charger) => {
        const chargerStatusIcon = charger.status === 'Offline' ? 'map__tooltip__offline__icon' : charger?.alarmStatus === 'Critical' && charger?.status === 'Online' ? 'map__tooltip__faulty__icon' : charger.status === 'Online' ? 'map__tooltip__online__icon' : '';
        return {
          latitude: parseFloat(charger?.latitude),
          longitude: parseFloat(charger?.longitude),
          status: charger?.alarmStatus === 'Critical' && charger?.status === 'Online' ? 'Faulty' : charger?.status,
          id: charger?.id,
          tooltipData: {
            id: charger?.id, headerText: charger.visibleId, headerOnClick: (): void => {
              navigate('/charger?chargerId=' + charger.visibleId, {
                state: {
                  layoutView: chargerValue?.length > 1 ? 'map' : 'list',
                  chargerId: charger?.id,
                  pageNumber: chargerListTablePropsRef?.current?.pageNumber || 1,
                  chargerValue: chargerValue,
                  individualChargerMapView: false,
                  charger: charger
                }
              })
            },
            data: [
              { icon: 'map__tooltip__customer__icon', text: `${charger.customerName}` },
              { icon: 'map__tooltip__connector__icon', text: `${charger.connectorTypes}` },
              { icon: chargerStatusIcon, text: `${charger?.alarmStatus === 'Critical' && charger?.status === 'Online' ? 'Faulty' : charger?.status}` },
            ],
          }
        };
      });
      if (mapDataPoint) {
        const filteredDataPoints = mapDataPoint.filter(i =>
          i.latitude != null && true && !isNaN(i.latitude) && i.longitude != null && true && !isNaN(i.longitude));
        setMapDataPoints(filteredDataPoints);
      } else {
        setMapDataPoints([]); // If there are no chargers coming from server.
      }
    } catch (error) {
      console.error('Error in refreshMapDataPoints:', error);
    }
  }, [chargerListTablePropsRef?.current, setMapDataPoints]);

  // TODO: Edit and View Button click
  const onIconClick = (rowData, mode) => {
    navigate('/charger-detail', { state: { mode: mode, rowData: rowData } })
  }

  const deleteIconClick = async (chargerId) => {
    setSelectedChargerId(chargerId);
    setIsDeleteDialogOpen(true);
  }

  const actionIconsList = useMemo(() => {
    return [

      {
        icon: 'viewIcon',
        isAccessible: access?.chargers?.view(),
        action: (_, rowData) => onIconClick(rowData, 'View')
      },
      {
        icon: 'sheildIcon',
        isAccessible: access?.chargers?.view(),
        action: onMapIconClick
      },
      {
        icon: 'pencilIcon',
        isAccessible: access?.chargers?.edit(),
        action: (_, rowData) => onIconClick(rowData, 'Edit')
      },
      // Needs to be deployed after uncommenting once we get approval.
      // {
      //   icon: 'deleteIcon',
      //   isAccessible:  access?.chargers?.edit(),
      //   action: (_, rowData) => deleteIconClick(rowData?.id)
      // },
      // {icon: 'pencilWhiteIcon',
      // isAccessible:  access?.chargers?.edit()
      // },
    ];
  }, [onMapIconClick, chargerListTablePropsRef?.current]);


  const handleClosePopOver = (): void => {
    setIsSidePopOverOpen(false)
    setAddChargerOption(null)
  }
  const handleFiltersClosePopOver = (): void => {
    setFiltersVisible(false);
  }

  // TODO: Table Layout Callbacks
  const mobileViewConfiguration = useMemo(() => {
    return {
      headerDataConfig: {
        headerLeftDataConfig: ['visibleId', 'model'],
        headerRightDataConfig: {
          actionIconsComponent: false,
          statusIconComponent: true,
        },
      },
      statusIconKey: 'status',
      statusIconMapper: {
        'Online': 'status__online__charger__icon',
        'Offline': 'status__offline__charger__icon'
      },
      statusIconBackgroundColorMapper: {
        'Online': 'var(--green-color)',
        'Offline': 'var(--grey-color)'
      },
      contentDataConfig: [
        [
          {
            key: 'softwareVersion',
            iconClassName: 'chargers__icon',
          },
          {
            key: 'customerName',
            iconClassName: 'customer__management__icon',
          },
        ],
        [
          {
            key: 'chargerSwVersion',
            iconClassName: 'alarm__management__icon  ',
          },
          {
            key: 'connectorType',
            iconClassName: 'navbar__logout__icon  ',
          },
        ],
      ],
    };
  }, []);

  const getTableRows = useCallback((): ICharger[] => {
    return chargers !== null && chargers?.length > 0 ? chargers : [];
  }, [chargers]);

  const chargerColumns = useMemo(() => {
    let columns = [
      {
        field: 'visibleId',
        flex: 1,
        maxWidth: 500,
        headerName: 'Charger',
        minWidth: 150,
        hideable: false,
        pinnable: false,
        renderCell: (params: GridRenderCellParams<GridValidRowModel>): JSX.Element => {
          const rowData = params?.row;
          return (
            rowData?.productType ?
              (<div
                className="individual__charger__wrap"
                onClick={(e) => {
                  e?.stopPropagation()
                  const action = {
                    type: chargerTypes.SET_CHARGER_REDUCER_STATE,
                    isChargerTablePropsRetained: true
                  }
                  dispatch(action)
                }
                }
              >
                <LinkButton
                  linkBtnId={rowData?.visibleId}
                  link={'/charger?chargerId=' + rowData.visibleId}
                  btnClassName="individual__charger__text"
                  btnText={rowData?.visibleId}
                  linkBtnState={{ chargerId: rowData?.id, charger: rowData }}
                />
              </div>) : (<div onClick={() => setIsNoProductTypeDialogOpen(true)}>{rowData?.visibleId}</div>)
          );
        },
      },
      {
        field: 'productType',
        headerName: 'Product Type',
        flex: 1,
        sortable: true,
        pinnable: false,
        minWidth: 120,
      },
      {
        field: 'model',
        headerName: 'Model',
        minWidth: 130,
        editable: false,
        pinnable: false,
        flex: 1,
      },
      {
        field: 'status',
        headerName: 'Status',
        minWidth: 90,
        flex: 1,
        editable: false,
        pinnable: false,
        renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
          return <CustomStatus
            statusText={params?.row?.status}
            statusIconClassName={params?.row?.status === 'Online' ? 'status__online__charger__icon' : 'status__offline__charger__icon'}
            backgroundColor={params?.row?.status === 'Online' ? 'var(--green-color)' : 'var(--grey-color)'} />;
        },
      },
      {
        field: 'customerName',
        headerName: 'Customer',
        flex: 1,
        sortable: true,
        pinnable: false,
        minWidth: 120,
      },
      {
        field: 'city',
        headerName: 'City',
        flex: 1,
        sortable: true,
        pinnable: false,
        minWidth: 120,
      },

      {
        field: 'softwareVersion',
        headerName: 'Software Version',
        flex: 1,
        sortable: true,
        pinnable: false,
        minWidth: 140,
      },
      {
        field: 'activeCertificate',
        headerName: 'Active Certificate',
        flex: 1,
        sortable: true,
        pinnable: false,
        minWidth: 120,
      },
      {
        field: 'connectorTypes',
        headerName: 'Connectors',
        flex: 1,
        sortable: true,
        pinnable: false,
        minWidth: 130,
        renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
          const rowData = params?.row;
          const connectorTypes = rowData?.connectorTypes;
          const labelId = params?.row?.id
          return (
            <CustomTooltipWithLabel label={connectorTypes} labelId={labelId} />
          );
        },
      },
      {
        field: 'lastPingTime',
        headerName: 'Last Ping Time',
        flex: 1,
        sortable: true,
        pinnable: false,
        minWidth: 140,
        renderCell: (params: GridRenderCellParams<GridValidRowModel>): ReactNode => {
          const rowData = params?.row;
          return rowData?.lastPingTime ? formatRelativeTimeForTable(new Date(rowData?.lastPingTime)) : '-';
        },
      },
      {
        field: 'lastUseChargingTime',
        headerName: 'Last Use Time',
        flex: 1,
        sortable: true,
        pinnable: false,
        minWidth: 140,
        renderCell: (params: GridRenderCellParams<GridValidRowModel>): ReactNode => {
          const rowData = params?.row;
          return rowData?.lastUseChargingTime ? formatRelativeTimeForTable(new Date(rowData?.lastUseChargingTime)) : '-';
        },
      },
      {
        field: 'chargerState',
        headerName: 'Availability',
        flex: 1,
        sortable: true,
        pinnable: false,
        minWidth: 140,
        renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
          const rowData = params?.row;
          return (
            <CustomTooltipWithLabel label={rowData?.chargerState === 'AVAILABLE' ? 'Available' : 'Unavailable'} labelId={'charger__dashboard__availability'} />
          );
        },
      },

      {
        field: 'visibility',
        headerName: 'Visibility',
        flex: 1,
        sortable: true,
        pinnable: false,
        minWidth: 100,
      },

      {
        field: 'alarmStatus',
        headerName: 'Alarm',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        sortable: true,
        pinnable: false,
        minWidth: 90,
        maxWidth: 90,
        renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
          const rowData = params?.row;
          const severityIcon = rowData?.alarmStatus === 'No Alarm' ? 'TOTAL' : rowData?.alarmStatus?.toUpperCase();
          const tooltipText = rowData?.alarmStatus === 'No Alarm' ? 'No Alarm' : alarmCardMapper[severityIcon]?.title;
          return (
            <div className="charger__connector__type__cell">
              <IconWithTooltip iconClassName={rowData?.alarmStatus === 'No Alarm' ? 'no_alarm_icon' : alarmCardMapper[severityIcon].alarmSummaryIcon}
                toolTipText={tooltipText} />

            </div>
          );
        },
      },

      {
        field: 'remarks',
        headerName: 'Notes',
        minWidth: 100,
        flex: 1,
        sortable: false,
        pinnable: false,
        renderCell: (params: GridRenderCellParams<GridValidRowModel>): JSX.Element => {
          const rowData = params?.row;
          return <CustomTooltipWithLabel labelId='charger__dashboard-notes' label={rowData?.remarks} />
        },
      },
      {
        field: 'action',
        headerName: 'Actions',
        flex: 1,
        sortable: false,
        pinnable: false,
        minWidth: 120,
        headerAlign: 'center',
        align: 'left',
        renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
          const rowData = params?.row;
          const allowedActions = rowData?.isDeletable === 'true' ? actionIconsList?.filter((icon) => icon?.icon !== 'pencilWhiteIcon') : actionIconsList?.filter((icon) => icon?.icon !== 'deleteIcon')
          return (
            <div className="charging__station__action__icons__wrap">
              <ActionIcons actionIconsList={allowedActions} data={rowData} />
            </div>
          );
        },
      },
    ];
    if (!enableVisibilityEdit) {
      columns = columns?.filter(column => column?.field !== 'visibility');
    }
    if (isOrgTypeCustomer) {
      columns = columns?.filter(column => column?.field != 'productType');
      columns = columns?.filter(column => column?.field != 'activeCertificate');
    }
    return columns;
  }, []);

  const layoutCallback = useCallback(async (pageNumber: number, pageSize: number, view: string, field: string, order: string, filterData, searchTerm) => {
    const tableProps: IChargerListTableProps = cloneDeep(chargerListTablePropsRef.current)
    const filters = filterData ? filterData : getCurrentFiltersValues('chargers')
    await dispatch(fetchChargersData({
      view: view ?? layoutView,
      sortBy: field || chargerListTablePropsRef?.current?.sortBy,
      order: order || chargerListTablePropsRef?.current?.order,
      pageSize,
      pageNumber,
      filters: filters,
      searchTerm: searchTerm != null && searchTerm != undefined ? searchTerm : chargerListTablePropsRef?.current?.searchTerm
    }));
    if (tableProps) {
      tableProps.view = view || layoutView
      tableProps.sortBy = field || chargerListTablePropsRef?.current?.sortBy;
      tableProps.order = order || chargerListTablePropsRef?.current?.order;
      tableProps.pageNumber = pageNumber;
      tableProps.pageSize = pageSize;
      if (searchTerm != null && searchTerm != undefined) {
        tableProps.searchTerm = searchTerm;
      }
    }
    await dispatch(setChargerListTableProps(tableProps))
  }, [layoutView, chargerListTablePropsRef?.current, dispatch]);

  // Refresh Page Handler
  const handleRefresh = useCallback(async () => {
    if (chargerListTablePropsRef?.current) {
      await layoutCallback(chargerListTablePropsRef?.current?.pageNumber, chargerListTablePropsRef?.current?.pageSize, chargerListTablePropsRef?.current?.view, chargerListTablePropsRef?.current?.sortBy, chargerListTablePropsRef?.current?.order, null, chargerListTablePropsRef?.current?.searchTerm)
    }
    if (layoutView === 'map') {
      await refreshMapDataPoints(chargersRef?.current)
    }
  }, [layoutView, chargerListTablePropsRef?.current, chargersRef?.current]);

  // Handler For Column Prefrence Chnages
  const handleColumnPrefrenceChange = useCallback(async (operation, data) => {
    await dispatch(setTableColumnPrefrences('chargers', operation, data))
  }, [])

  // ********************************************
  // TODO: Filter side pop up 
  // onchange -> handles onchange of all the dropdowns, layout props -> pass the required dropdowns, footer button action
  const handleFilterDataChange = useCallback(async (val, filterKey, isGlobal) => {
    const res = await setFilterData(val, filterKey, 'chargers', 'SET', isGlobal ? isGlobal : false)(dispatch)
    if (res?.message === 'Action dispatched successfully') {
      if (chargerListTablePropsRef?.current) {
        await layoutCallback(1, chargerListTablePropsRef?.current?.pageSize, chargerListTablePropsRef?.current?.view, chargerListTablePropsRef?.current?.sortBy, chargerListTablePropsRef?.current?.order, res?.filters, chargerListTablePropsRef?.current?.searchTerm)
      }
    }
  }, [chargerListTablePropsRef?.current, dispatch, chargersRef?.current, layoutView])


  const chargerFilterProps: FilterProp[] = useMemo(() => {
    let chargerFilters: FilterProp[] =
      [
        {
          filterLabel: 'Connector Types',
          filterType: 'dropdown',
          filterId: 'chargerType',
          filterDropdownProps: {
            selectValue: getFilterSelectedValue('chargerType', 'chargers', false),
            selectOptions: chargerTypeReferenceData ?? [],
            handleSelectOptionChange: (val): void => {
              handleFilterDataChange(val, 'chargerType', false)
            },
            selectDropDownId: 'charger-charger-type-filter-dropdown',
            isMultiSelect: false
          }
        },
        {
          filterLabel: 'Product Type',
          filterType: 'dropdown',
          filterId: 'productType',
          filterDropdownProps: {
            selectValue: getFilterSelectedValue('productType', 'chargers', false),
            selectOptions: chargerProductTypeReferenceData ?? [],
            handleSelectOptionChange: (val): void => {
              handleFilterDataChange(val, 'productType', false)
            },
            selectDropDownId: 'charger-charger-type-filter-dropdown',
            isMultiSelect: true
          }
        },
        {
          filterLabel: 'Active Certificate',
          filterType: 'dropdown',
          filterId: 'activeCertificate',
          filterDropdownProps: {
            selectValue: getFilterSelectedValue('activeCertificate', 'chargers', false),
            selectOptions: chargerActiveCertificateReferenceData ?? [],
            handleSelectOptionChange: (val): void => {
              handleFilterDataChange(val, 'activeCertificate', false)
            },
            selectDropDownId: 'charger-charger-type-filter-dropdown',
            isMultiSelect: true
          }
        },
        {
          filterLabel: 'Charging Stations',
          filterType: 'dropdown',
          filterId: 'chargingStationId',
          filterDropdownProps: {
            selectValue: getFilterSelectedValue('chargingStationId', 'chargers', false),
            selectOptions: chargingStationReferenceData ?? [],
            handleSelectOptionChange: (val): void => {
              handleFilterDataChange(val, 'chargingStationId', false)
            },
            selectDropDownId: 'charger-chargingStationId-type-filter-dropdown',
            isMultiSelect: true
          }
        },
        {
          filterLabel: 'Location',
          filterType: 'dropdown',
          filterId: 'Location',
          filterDropdownProps: {
            selectValue: getFilterSelectedValue('location', 'chargers', false),
            selectOptions: chargerLocationReferenceData ?? [],
            handleSelectOptionChange: (val): void => {
              handleFilterDataChange(val, 'location', false)
            },
            selectDropDownId: 'charger-location-filter-dropdown',
            isMultiSelect: true
          }
        },
        {
          filterLabel: 'Status',
          filterType: 'dropdown',
          filterId: 'Status',
          filterDropdownProps: {
            selectValue: getFilterSelectedValue('chargerStatus', 'chargers', false),
            selectOptions: chargerStatusReferenceData ?? [],
            handleSelectOptionChange: (val): void => {
              handleFilterDataChange(val, 'chargerStatus', false)
            },
            selectDropDownId: 'charger-status-filter-dropdown',
            isMultiSelect: true
          }
        },
        {
          filterLabel: 'Alarm Severity',
          filterType: 'dropdown',
          filterId: 'alarmStatus',
          filterDropdownProps: {
            selectValue: getFilterSelectedValue('alarmStatus', 'chargers', false),
            selectOptions: alarmSeverityReferenceData ?? [],
            handleSelectOptionChange: (val): void => {
              handleFilterDataChange(val, 'alarmStatus', false)
            },
            selectDropDownId: 'charger-severity-filter-dropdown',
            isMultiSelect: true
          }
        },
        {
          filterLabel: 'Visibility',
          filterType: 'dropdown',
          filterId: 'visibility',
          filterDropdownProps: {
            selectValue: getFilterSelectedValue('visibility', 'chargers', false),
            selectOptions: chargerVisibilityReferenceData ?? [],
            handleSelectOptionChange: (val): void => {
              handleFilterDataChange(val, 'visibility', false)
            },
            selectDropDownId: 'charger-software-version-filter-dropdown',
            isMultiSelect: true
          }
        },

        {
          filterLabel: 'Software Version',
          filterType: 'dropdown',
          filterId: 'softwareVersion',
          filterDropdownProps: {
            selectValue: getFilterSelectedValue('softwareVersion', 'chargers', false),
            selectOptions: softwareVersionReferenceData ?? [],
            handleSelectOptionChange: (val): void => {
              handleFilterDataChange(val, 'softwareVersion', false)
            },
            selectDropDownId: 'charger-software-version-filter-dropdown',
            isMultiSelect: true
          }
        },
      ]
    if (isOrgTypeCustomer) {
      chargerFilters = chargerFilters?.filter(item => item?.filterId != 'productType');
      chargerFilters = chargerFilters?.filter(item => item?.filterId != 'activeCertificate');
    }
    if (!enableVisibilityEdit) {
      chargerFilters = chargerFilters?.filter(filter => filter.filterId !== 'visibility')
    }
    return chargerFilters
  }, [chargerTypeReferenceData, softwareVersionReferenceData, chargerProductTypeReferenceData, chargingStationReferenceData, chargerLocationReferenceData, chargerActiveCertificateReferenceData, chargerStatusReferenceData, alarmSeverityReferenceData, screenFilters, chargerVisibilityReferenceData, chargerListTablePropsRef?.current, chargersRef?.current, layoutView])

  const onResetButtonClick = useCallback(async (callListApi: boolean) => {
    const res = await setFilterData(null, '', 'chargers', 'CLEAR', false)(dispatch);
    if (res?.message === 'Action dispatched successfully') {
      if (chargerListTablePropsRef?.current && callListApi) {
        layoutCallback(1, chargerListTablePropsRef?.current?.pageSize, chargerListTablePropsRef?.current?.view, chargerListTablePropsRef?.current?.sortBy, chargerListTablePropsRef?.current?.order, res?.filters, chargerListTablePropsRef?.current?.searchTerm)
      }
    }
    setRedirectedStateForChargerFilters(false)(dispatch)
  }, [chargerListTablePropsRef?.current, chargerFiltersAppliedFromRedirection])


  // TODO: Redux state Clean up
  const chargerCleanUpStates = useCallback(() => {
    // : Partial<ChargerAction>
    const action = {
      type: chargerTypes.CLEAR_CHARGER_LIST_DATA,
    }
    dispatch(action);
    if (chargerFiltersAppliedFromRedirection) {
      onResetButtonClick(false)
    } else {
      setRedirectedStateForChargerFilters(false)(dispatch)
    }
  }, [chargerFiltersAppliedFromRedirection])

  useEffect(() => {
    window.addEventListener('beforeunload', chargerCleanUpStates);
    return (): void => {
      chargerCleanUpStates()
      window.removeEventListener('beforeunload', chargerCleanUpStates);
    }
  }, [])

  const getMapData = useCallback(() => {
    if (mapDataPoints) {
      return mapDataPoints ?? []
    }
  }, [mapDataPoints])

  const handlePopOverClose = (refresh: boolean): void => {
    setIsSidePopOverOpen(false)
    if (refresh) {
      layoutCallback(1, chargerListTablePropsRef?.current?.pageSize, chargerListTablePropsRef?.current?.view, chargerListTablePropsRef?.current?.sortBy, chargerListTablePropsRef?.current?.order, null, chargerListTablePropsRef?.current?.searchTerm)
    }
  }

  // ***********************************************************
  // TODO: Export Handlers
  const handleClose = (): void => {
    setExportAnchorEl(null);
  };
  const handleCloseAddCharger = (): void => {
    setAddChargerOption(null);
  }

  const fileDownloadSuccessToast = useCallback((): Id => {
    setExportingFile(false);
    return toast.success('File downloaded.');
  }, []);

  const fileDownloadFailureToast = useCallback((): Id => {
    setExportingFile(false);
    return toast.warn('Something went wrong. Please export again.');
  }, []);


  const onExportBtnClick = useCallback(async (fileType: 'csv' | 'xlsx'): Promise<void> => {
    if (exportingFile) {
      // If already exporting, prevent another export
      return;
    }
    try {
      const response = await dispatch(exportToCsvForChargerList({
        fileType: fileType,
        filters: getCurrentFiltersValues('chargers')
      }));
      if (response?.status === 202 || response?.status === 200) {
        downloadFile({ url: response?.data?.url });
        fileDownloadSuccessToast()
      } else {
        fileDownloadFailureToast();
        setExportAnchorEl(null);
      }
    } finally {
      // Ensure that exporting status is updated even if there's an error
      setExportingFile(false);
      setExportAnchorEl(null);

    }
  }, [exportingFile, fileDownloadSuccessToast, fileDownloadFailureToast])


  // ***************************************************************
  // TODO : Toggle between map and list view & Handling state change using the callback passed in TableDashboard
  const handleLayoutToggle = useCallback(async (newLayoutVal, newIndividualChargerMapView?) => {
    if (newLayoutVal === 'map') {
      setDefaultMapCenter(true);
      setMapSelectedChargerDataPoint({ latitude: 0, longitude: 0, id: '' });
      await refreshMapDataPoints(chargersRef.current)
    }
    setLayoutView(newLayoutVal);
    if (newIndividualChargerMapView !== undefined && individualChargerMapView != newIndividualChargerMapView) setIndividualChargerMapView(prevState => !prevState);     // when we click on Back button in Table Dashboard, we pass newIndividualChargerMapView as false 
  }, [chargersRef.current, setIndividualChargerMapView])


  const layoutsConfiguration = useMemo(() => {
    return [
      {
        toolTipText: 'List View',
        onIconClick: (): void => {
          handleLayoutToggle('list')
        },
        layout: 'list',
        iconClassName: 'rms__table__dashboard-list__view__icon'
      },
      {
        toolTipText: 'Grid View',
        onIconClick: (): void => {
          handleLayoutToggle('grid')
        },
        layout: 'grid',
        iconClassName: 'rms__table__dashboard-grid__view__icon'
      },
      {
        toolTipText: 'Map View',
        onIconClick: (): void => {
          handleLayoutToggle('map')
        },
        layout: 'map',
        iconClassName: 'rms__table__dashboard-map__view__icon'
      }
    ]
  }, [handleLayoutToggle])

  // TODO : Render Header Text
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const setChargerHeader = useCallback(() => {
    const headerTextMapper = {
      'Offline': 'Offline Chargers',
      'Online': 'Online Chargers',
      'all': 'Chargers',
      'Faulty': 'Faulty Chargers',
      'Healthy': 'Healthy Chargers'
    }
    if (location.state?.chargerStatusId) return headerTextMapper[location.state?.chargerStatusId]
    return 'Chargers'
  }, [location.state?.chargerStatusId])

  // TODO: BreadCrumb navigation Props
  const breadCrumbs = createBreadCrumbProps({
    breadCrumbProps:
      [
        {
          objectType: 'link',
          id: 'chargers',
          linkBtnState: location?.state,
          handleOnClick: () => {
            setLayoutView('list')
            setIndividualChargerMapView(false)
          }
        },
        {
          objectType: 'text',
          id: 'text',
          text: headerIndividualChargerMapView
        }
      ]
  })

  const filterFooterBtns = useMemo(() => {
    return [
      {
        buttonText: 'Reset',
        buttonId: 'Reset',
        btnClassName: 'secondary__btn',
        handleClick: (): Promise<void> => onResetButtonClick(true),
        isDisabled: false,
        buttonVariant: 'outlined',
      },
    ];
  }, [onResetButtonClick]);

  const handleDeleteDialogClose = useCallback(() => {
    setIsDeleteDialogOpen(false);
    setSelectedChargerId(null);
  }, []);

  const handleDeleteCharger = useCallback(async () => {
    setDeleteLoader(true);
    const { data, status } = await dispatch(deleteCharger({
      chargerId: selectedChargerId
    }));
    if ((status === 200 || status === 202) && data?.message === 'Success') {
      setDeleteLoader(false);
      toast?.info('Charger deleted successfully');
      handleRefresh()
    } else {
      setDeleteLoader(false);
      toast?.error(data)
    }
    handleDeleteDialogClose();
  }, [dispatch, selectedChargerId, handleDeleteDialogClose, chargerListTablePropsRef?.current]);


  useEffect(() => {
    if (location.state) {
      layoutCallback(1, chargerListTablePropsRef?.current?.pageSize, chargerListTablePropsRef?.current?.view, chargerListTablePropsRef?.current?.sortBy, chargerListTablePropsRef?.current?.order, null, chargerListTablePropsRef?.current?.searchTerm)
    }
  }, [location.state]);

  return (
    <>
      <TableDashboard
        layoutsEnabled={true}
        tableId="manage-chargers-table"
        tableClassName="manage__chargers__table"
        header={individualChargerMapView ? headerIndividualChargerMapView : setChargerHeader()}
        searchBoxIncluded={true}
        searchBoxConfiguration={searchBoxConfiguration}
        gridColumns={chargerColumns}
        tableRows={getTableRows()}
        mobileViewConfiguration={mobileViewConfiguration}
        layouts={layouts}
        layoutView={layoutView}
        headerActionBtns={headerActionBtns}
        // filterBtn={filterBtn}
        listLayoutCallBack={layoutCallback}
        mapDataPoints={getMapData()}
        totalCount={chargerCount}
        showSkeleton={!chargers}
        totalCountText={'chargers'}
        showLoader={chargerTableLoader}
        mapFocusedDataPoint={mapSelectedChargerDataPoint}
        usedefaultMapCenter={defaultMapCenter}
        customNoDataComponent={NoDataComponentChargers}
        pageNumber={chargerListTableProps?.pageNumber}
        tablePageSize={chargerListTableProps?.pageSize}
        sortField={chargerListTableProps?.sortBy}
        sortOrder={chargerListTableProps?.order}
        layoutsConfiguration={layoutsConfiguration}
        individualChargerMapView={individualChargerMapView}
        breadCrumbs={breadCrumbs}
        showRefreshButton={true}
        handleRefresh={handleRefresh}
        columnPrefrences={screenTableColumnPrefrences?.find((item) => item.screen === 'chargers')}
        handleColumnPrefrenceChange={handleColumnPrefrenceChange}
        leftPinnedColumns={['visibleId']}
      />
      <Menu
        id="export-option-dropdown"
        anchorEl={addChargerOption}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className='export__option__dropdown_bulk'
        sx={{ top: '30px' }}
        open={Boolean(addChargerOption)}
        onClose={handleCloseAddCharger}
      >
        <MenuItem>
          <div className='export__btn-option' onClick={(e) => {
            e?.stopPropagation();
            navigate('/add-charger', { state: { mode: 'Add' } })
          }}>
            Add Charger
          </div>
        </MenuItem>
        <MenuItem>
          <div className='export__btn-option' onClick={(e) => {
            e?.stopPropagation();
            setIsSidePopOverOpen(true);
            handleCloseAddCharger()
          }}>
            Bulk Upload
          </div>
        </MenuItem>
      </Menu>
      <Menu
        id="export-option-dropdown"
        anchorEl={exportAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className='export__option__dropdown'
        sx={{ top: '30px' }}
        open={Boolean(exportAnchorEl)}
        onClose={handleClose}
      >
        <MenuItem>
          <div className='export__btn-option' onClick={(e) => {
            e?.stopPropagation();
            onExportBtnClick('csv')
          }}>
            To CSV
          </div>
        </MenuItem>
        <MenuItem>
          <div className='export__btn-option' onClick={(e) => {
            e?.stopPropagation();
            onExportBtnClick('xlsx')
          }}>
            To Excel
          </div>
        </MenuItem>
      </Menu>
      {isSidePopOverOpen && <SidePopover
        id={'charger__bulk__upload__side__popover'}
        open={isSidePopOverOpen}
        containerClassName='charger__bulk__upload__side__popover'
        handleClose={handleClosePopOver}
        headerText={'Add Chargers To Exicom SPIN RMS'}
        // eslint-disable-next-line react/no-children-prop
        children={<AddBulkCharger handlePopOverClose={handlePopOverClose} />}
      />
      }
      {filtersVisible && <SidePopover
        id={'manage__chargers__side__filters'}
        open={filtersVisible}
        containerClassName='manage__chargers__side__filters'
        handleClose={handleFiltersClosePopOver}
        headerText={' Add Filters'}
        // eslint-disable-next-line react/no-children-prop
        children={<FilterLayout requiredReferenceDataCallbacks={['location', 'status', 'softwareVersion', 'productType', 'activeCertificate', 'severity', 'visibility', 'chargingStationId', 'chargerType']/* ['location', 'status', 'softwareVersion', 'severity', 'chargerVisibleId'] */}
          filters={chargerFilterProps} handleFilterDataChange={handleFilterDataChange} />}
        btnsList={filterFooterBtns}
      />
      }
      <CustomDialog
        dialogConfig={{
          dialogDescription: 'Are you sure you want to delete this charger?',
          dialogCancelTitle: 'Cancel',
          dialogOkTitle: 'Delete',
        }}
        show={isDeleteDialogOpen}
        handleClose={handleDeleteDialogClose}
        handleSubmit={handleDeleteCharger}
      />
      <CustomDialog
        dialogConfig={{
          dialogDescription: 'Charger information is not available, as the charger has not yet communicated with RMS.',
          dialogOkTitle: 'Close',
        }}
        show={isNoProductTypeDialogOpen}
        handleSubmit={() => { setIsNoProductTypeDialogOpen(false) }}
        deleteLoader={deleteLoader}
      />

    </>
  );
})

export default ManageChargersDashboard;
